import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { BasicKnowledgeGroups } from '../components/knowledge/basicKnowledgeGroups'
import { Seo } from '../components/seo/Seo'
import { AppLocale } from '@hrk/types'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Wissensbereiche: React.FC = () => {
  const currentSlug = '/wissensbereiche'
  const currentLocale: AppLocale = 'de'
  const seoTitle = 'Wissensbereiche'
  const seoDescription = 'Die Wissensbereiche von hoch und weit'

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'KnowledgeAreas' })

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentLocale={currentLocale}
        currentTitle={seoTitle}
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        otherLocales={[{ locale: 'en', slug: '/en/knowledge-areas/' }]}
      >
        <BasicKnowledgeGroups />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/wissensbereiche/`} />
}

export default Wissensbereiche
